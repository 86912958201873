<template>
  <div class="statistics">
    <div class="statistics__data home-team">
      <div class="statistics__data__periods">
        <div v-for="(period, index) of periodsData.homeTeamPeriods" :key="index">
          <GamePeriodData
            :title="period.title"
            :data="period.data"
            :is-first="index === 0"
            :is-last="periodsData.homeTeamPeriods.length - 1 === index"
          />
        </div>
      </div>
      <div class="statistics__data__info">{{ type }} de {{ teams[0] }} en promedio por partido</div>
    </div>
    <div class="statistics__data league">
      <div>{{ Number.isNaN(periodTotal) ? 0 : periodTotal | toFixed2 }}</div>
      <div class="statistics__data__info">{{ type }} totales en promedio por partido</div>
    </div>
    <div class="statistics__data away-team">
      <div class="statistics__data__periods">
        <div v-for="(period, index) of periodsData.awayTeamPeriods" :key="index">
          <GamePeriodData
            :title="period.title"
            :data="period.data"
            :is-first="index === 0"
            :is-last="periodsData.awayTeamPeriods.length - 1 === index"
          />
        </div>
      </div>
      <div class="statistics__data__info">{{ type }} de {{ teams[1] }} en promedio por partido</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'StatisticsCornersGoalsDesktop',
  components: {
    GamePeriodData: () => import('@/components/Sections/GolTipster-v2/General/GamePeriodData'),
  },
  props: {
    dataByPeriod: {
      type: Object,
      default: () => ({
        homeTeam: {
          firstTime: 0,
          secondTime: 0,
        },
        awayTeam: {
          firstTime: 0,
          secondTime: 0,
        },
      }),
    },
    isCorners: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('betsGeneral', ['selectedGame', 'filterGamePeriod']),
    teams() {
      let teams = ['', ''];
      if (this.selectedGame) {
        teams[0] = this.selectedGame.homeTeam.colloquial_name;
        teams[1] = this.selectedGame.awayTeam.colloquial_name;
      }
      return teams;
    },
    type() {
      return this.isCorners ? 'Tiros de esquina' : 'Goles';
    },
    periodsData() {
      return {
        homeTeamPeriods: [
          {
            title: '1ros. Tiempos',
            data: this.dataByPeriod.homeTeam.firstTime,
          },
          {
            title: '2dos. Tiempos',
            data: this.dataByPeriod.homeTeam.secondTime,
          },
          {
            title: 'total',
            data: this.dataByPeriod.homeTeam.firstTime + this.dataByPeriod.homeTeam.secondTime,
          },
        ],
        awayTeamPeriods: [
          {
            title: '1ros. Tiempos',
            data: this.dataByPeriod.awayTeam.firstTime,
          },
          {
            title: '2dos. Tiempos',
            data: this.dataByPeriod.awayTeam.secondTime,
          },
          {
            title: 'total',
            data: this.dataByPeriod.awayTeam.firstTime + this.dataByPeriod.awayTeam.secondTime,
          },
        ],
      };
    },
    periodTotal() {
      if (this.filterGamePeriod === 0) {
        return this.periodsData.homeTeamPeriods[2].data + this.periodsData.awayTeamPeriods[2].data;
      } else {
        return (
          this.periodsData.homeTeamPeriods[this.filterGamePeriod - 1].data +
          this.periodsData.awayTeamPeriods[this.filterGamePeriod - 1].data
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.statistics {
  display: grid;
  grid-template-columns: 37% 26% 37%;

  &__data {
    display: flex;
    flex-direction: column;

    &__periods {
      display: flex;

      & > div:nth-child(2) {
        margin: 0 0.1rem;
      }
    }

    &.league > div:first-child {
      font-family: Roboto-Black, sans-serif;
      width: 100%;
      padding: 1.1rem 0 0.9rem;
      border-radius: 24px;
      background-color: #cbee6b;
      font-size: 3.5rem;
      font-weight: 800;
      line-height: 0.89;
    }

    &__info {
      font-family: Roboto-Regular, sans-serif;
      margin-top: 0.8rem;
      font-size: 0.81rem;
      color: #5c686f;
    }
  }

  @media screen and (max-width: 980px) {
    grid-template-columns: 40% 20% 40%;
  }
}

.home-team {
  align-items: flex-start;
}

.away-team {
  align-items: flex-end;
}

.league {
  align-items: center;
}
</style>
